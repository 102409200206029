import React from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Container from "../components/Container";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { Flex, Box } from "@rebass/grid";
import H1 from "../components/H1";
import H2 from "../components/H2";
import H3 from "../components/H3";
import Lead from "../components/Lead";
import Paragraph from "../components/Paragraph";
import Card from "../components/Card";
import Button from "../components/Button";
import ExternalLink from "../components/ExternalLink";

import StarBackground from "../../static/img/star.svg";

const Price = styled.div`
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
`;

const PriceInfo = styled.div`
  opacity: 0.5;
  font-size: 0.8em;
  line-height: 1.5;
  margin-left: 1rem;
`;

const CheckList = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 2rem;
`;

const CheckItem = styled.li`
  display: flex;
  margin: 1rem 0;
  line-height: 1.5em;
`;

const CheckMark = styled.div`
  color: #05c46b;
  font-weight: bold;
  padding-right: 0.5em;
`;

const CheckText = styled.div``;

const SpecialOffer = styled.div`
  padding: 2rem;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.primary};
  color: #fff;
  margin-bottom: 6rem;
`;

const StarCard = styled(Card)`
  background: url(${StarBackground});
  background-position: 1rem 1rem;
  background-repeat: no-repeat;
`;

export const PricingPageTemplate = ({
  title,
  metadescription,
  description,
  pricing,
  platforms,
  questions,
  specialoffer
}) => (
  <Container>
    <Helmet title={`Tarieven | Appmantle`}>
      <meta name="description" content={metadescription} />
      <link rel="canonical" href="https://appmantle.com/tarieven/" />
    </Helmet>
    <Flex>
      <Box width={[1, 2 / 3, 1 / 2]} mt={[4, 5, 6]} mb={[4, 5, 5]}>
        <H1 as="h1">{title}</H1>
        <Lead>{description}</Lead>
      </Box>
    </Flex>
    {specialoffer.enabled && (
      <SpecialOffer>
        <H2>{specialoffer.title}</H2>
        <Paragraph>{specialoffer.description}</Paragraph>
      </SpecialOffer>
    )}
    <Flex flexWrap="wrap" mx={-3}>
      {pricing.plans.map(plan => (
        <Box width={[1, 1 / 3, 1 / 3]} px={3} mb={[5, 0, 0]}>
          <H3>{plan.plan}</H3>
          <Price>
            <H1>{plan.price}</H1>
            {plan.monthly && (
              <PriceInfo>
                excl. btw
                <br />
                per maand
              </PriceInfo>
            )}
          </Price>
          {plan.contact ? (
            <Button as={OutboundLink} href="mailto:hallo@appmantle.com">
              Neem contact op
            </Button>
          ) : (
            <Button
              as={OutboundLink}
              href="https://dashboard.appmantle.com/signup"
              withArrow
              primary={plan.buttonPrimary}
            >
              {plan.buttonText}
            </Button>
          )}
          <CheckList>
            {plan.items.map(item => (
              <CheckItem>
                <CheckMark>✓</CheckMark>
                <CheckText>{item}</CheckText>
              </CheckItem>
            ))}
          </CheckList>
        </Box>
      ))}
    </Flex>
    <Box mt={6}>
      <StarCard>
        <Flex flexWrap="wrap" mx={-3} my={3}>
          <Box width={1} px={4}>
            <H3>{platforms.title}</H3>
            <Paragraph>{platforms.description}</Paragraph>
          </Box>
          <Box width={[1, 1 / 2, 1 / 2]} px={4} mb={[3, 0, 0]}>
            <p>{platforms.ios.description}</p>
            <ExternalLink href={platforms.ios.link}>
              Meer informatie
            </ExternalLink>
          </Box>
          <Box width={[1, 1 / 2, 1 / 2]} px={4}>
            <p>{platforms.android.description}</p>
            <ExternalLink href={platforms.android.link}>
              Meer informatie
            </ExternalLink>
          </Box>
        </Flex>
      </StarCard>
    </Box>
    <Flex>
      <Box mx={[0, 5, 6]} my={5}>
        {questions.map(question => (
          <Box my={5}>
            <H2>{question.title}</H2>
            <Paragraph
              dangerouslySetInnerHTML={{
                __html: question.description
              }}
            />
          </Box>
        ))}
      </Box>
    </Flex>
  </Container>
);

const PricingPage = ({ data }) => {
  console.log(data);
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout>
      <PricingPageTemplate
        title={frontmatter.title}
        metadescription={frontmatter.metadescription}
        description={frontmatter.description}
        pricing={frontmatter.pricing}
        platforms={frontmatter.platforms}
        questions={frontmatter.questions}
        specialoffer={frontmatter.specialoffer}
      />
    </Layout>
  );
};

export default PricingPage;

export const PricingPageQuery = graphql`
  query PricingPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        metadescription
        description
        specialoffer {
          enabled
          title
          description
        }
        pricing {
          plans {
            buttonText
            buttonPrimary
            items
            plan
            price
            contact
            monthly
          }
        }
        platforms {
          title
          description
          ios {
            description
            link
          }
          android {
            description
            link
          }
        }
        questions {
          title
          description
        }
      }
    }
  }
`;
