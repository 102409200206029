import React from "react";
import styled, { css } from "styled-components";

const Style = styled.div`
  padding: 2rem;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 1px 3px;
  border: 1px solid #e8e8e8;
`;

export default function Button({ children, ...props }) {
  return (
    <Style  {...props}>
      {children}
    </Style>
  );
}
